import { Injectable, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { Router, ActivatedRoute } from '@angular/router'
import { StorageService } from '../storage/storage.service'

@Injectable({
    providedIn: 'root',
})
export class AppTranslateService {
    #FALLBACK_LOCALE = 'en'
    #LOCAL_STORE_NAME = 'idb-locale'
    #DEFAULT_LOCALE = this.getBrowserLang()
    locale$ = new BehaviorSubject<string | null>(null)

    static readonly language_list = [
        {
            id: 'de',
            serverLocale: 'de-DE',
            name: 'LANGUAGE.DEUTSCH',
            imgSrc: 'assets/images/landing-page/german.svg',
        },
        {
            id: 'en',
            serverLocale: 'en-US',
            name: 'LANGUAGE.ENGLISH',
            imgSrc: 'assets/images/landing-page/british.svg',
        },
    ]

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private localStore: StorageService,
        private translate: TranslateService
    ) {}

    /**
     * This function is called to initialize the default/fallback transalation and create route change event listner
     */
    init() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['locale']) {
                // next level option to avoid persistent store of localization key
                if (params['viewOnly']) {
                    this.translate.use(params['locale'])
                } else {
                    this.use(params['locale'])
                }
            }
        })

        this.translate.use(this.getLocale() as any)
    }

    get locale() {
        return this.locale$.value || this.#DEFAULT_LOCALE
    }

    /**
     * This function is used to change the locale value
     * @param locale - is the new language code
     */
    use(locale: string): void {
        this.locale$.next(locale)
        this.translate.use(locale)
        this.localStore.add(this.#LOCAL_STORE_NAME, locale)
    }

    /**
     * This function is used to read the active locale value from the store
     */
    getLocale() {
        return this.localStore.get(this.#LOCAL_STORE_NAME) || this.#DEFAULT_LOCALE
    }

    /**
     *
     * @returns this will return the browser default language
     */
    getBrowserLang() {
        const availableLangs = this.getLangs()
        const browserLang = this.translate.getBrowserLang()
        return availableLangs.includes(browserLang as any) ? browserLang : this.#FALLBACK_LOCALE
    }

    /**
     *
     * @returns Returns an array of currently available langs
     */
    getLangs() {
        return AppTranslateService.language_list.map((d) => d.id)
    }

    /**
     *
     * @param locale the browser translate code
     * @returns return the server translate code
     */
    findServerLocale(locale: string) {
        return AppTranslateService.language_list.find((d) => d.id === locale)?.serverLocale
    }

    /**
     *
     * @param locale the server translate code
     * @returns return the browser translate code
     */
    findBrowserLocale(locale: string) {
        return AppTranslateService.language_list.find((d) => d.serverLocale === locale)?.id
    }

    /**
     * This function is used to save transalate code in BehaviorSubject
     * @param locale is the language code
     */
    changeUserLocale(locale: string) {
        this.locale$.next(locale)
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { locale },
            queryParamsHandling: 'merge',
        })
    }
}
