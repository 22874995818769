export const environment = {
  production: true,
  name: 'dev',
  featureList: {
    app: true,
    landingPage: true,
    dashboard: true,
    login: true,
  },
  microservices: {
    cmn: {
      name: 'sandbox',
      appClientId: '2ao79lrj79mp337ssa0ldshcm9',
      userPoolId: 'eu-central-1_NDoggezhf',
      userPoolRegion: 'eu-central-1',
      channel: 'BALON_APP_ACADEMY',
    },
    fwh: {
      name: 'stage',
      appClientId: '2ao79lrj79mp337ssa0ldshcm9',
    },
    balonML: {
      name: 'dev',
      appClientId: '2ao79lrj79mp337ssa0ldshcm9',
    },
  },
  consents: {
    consent: '743f8499-cbbb-4e36-9ab8-9c081b4a3335',
    connectivity: '4f11cdba-27ec-45b6-9985-b70dcafa885d',
    language: '54546c2e-2fe1-401c-9640-672b79c84a12',
    defaults: '31e8930e-d0b6-4256-a305-4257af8ace79',
    analytics: 'c6a1aa3f-b010-42c7-862b-5c95950c1ee9',
    crashlytics: '45126b7c-b6d5-4ffe-9ee9-c17ee1181228',
  },
};
