import { Component } from '@angular/core'
import { AppTranslateService } from './core/services/app-translate/app-translate.service'
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(private translateService: AppTranslateService) {
        this.translateService.init()
    }
}