import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { firstValueFrom } from 'rxjs';
import { ErrorPageModule } from '@ct-fwh/ui-component';
import { ToastModule } from '@lib/toast';

function translateInitFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return firstValueFrom(translate.use('en'));
  };
}

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    ErrorPageModule,
    ToastModule.forRoot(),
  ],
  providers: [
    [
      {
        provide: APP_INITIALIZER,
        deps: [TranslateService],
        multi: true,
        useFactory: translateInitFactory,
      },
    ],
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
