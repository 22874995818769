import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './core/guards/auth.guard'
import { ErrorPageComponent } from '@ct-fwh/ui-component'
import { environment } from 'src/environments/environment'

const featureList: Record<string, boolean> = environment.featureList

const featureRoutes: Routes = []

const appRoutes: Routes = []

let defaultRedirect = ''

if (featureList.dashboard) {
    appRoutes.push({
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    })

    defaultRedirect = 'dashboard'
}

if (featureList.login) {
    appRoutes.push({
        path: 'login',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    })

    defaultRedirect = 'login'
}


if (featureList.app) {
    featureRoutes.push({
        path: '',
        loadChildren: () => import('./modules/app/app.module').then((m) => m.AppModule),
    })
}

const routes: Routes = [
    {
        path: '',
        redirectTo: defaultRedirect,
        pathMatch: 'full',
    },
    ...featureRoutes,
    {
        path: '404',
        component: ErrorPageComponent,
    },
    {
        path: '**',
        redirectTo: '/404',
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // initialNavigation: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

export const APP_CHILD_ROUTES = appRoutes
