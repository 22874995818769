import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ToastService } from './services/toast.service';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      toastClass: 'toast',
    }),
  ],
})
export class ToastModule {
  constructor(@Optional() @SkipSelf() parent: ToastModule) {
    // hard to test and uneccessary really
    // istanbul ignore if
    if (parent) {
      throw new Error('Toast module was already imported');
    }
  }
  public static forRoot(): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [ToastService],
    };
  }
}
